import logo from './logo.svg';
import './App.css';
import Header from './Header';
import Menu from './Menu';
import Dashboard from './Dashboard';
import Footer from './Footer';
import MbaDashboard from './components/MbaPgdm/Dashboard'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import OtherDashboard from './components/Other/Dashboard'
import PCMDashboard from './components/PCM/Dashboard'
import PCBDashboard from './components/PCB/Dashboard'
import EnggDashboard from './components/Engg/Dashboard'
import Markey from './components/Markey';
import CounsellingMarkey from './components/CounsellingMarkey';
import RegDashboard from './components/RegDashboard';
import Login from './components/Login';
import Logout from './components/Logout';
import AddUser from './components/refferal/AddUser';
import RefferalList from './components/refferal/RefferalList';
import RefferalLink from './components/refferal/RefferalLink';
import RefferalLogin from './components/RefferalLogin';
import ViewReportRefferal from './components/refferal/ViewReportRefferal';
import PartialStudents from './components/PartialStudents';
import UnverifiedStudent from './components/UnverifiedStudent';
import BDMLogin from './components/BDM/Login'
import Register from './components/refferal/Register';
import TotalRefferalLink from './pages/RefferalList'
import RefferalStudent from './pages/RefferalStudent';
import CounsellingDashboard from '../src/counselling/Index'
import ExaminationsDashboard from '../src/examination/Index'
import TotalExaminer from '../src/examination/TotalStudent'
import CounsellingStudents from '../src/counselling/CounsellingStudents'
import CounsellingPayments from '../src/counselling/CounsellingPayments'
import PartialPayments from '../src/counselling/PartialFees'
import ConfirmSeats from '../src/counselling/SeatConfirm'
import StudentProfile from '../src/counselling/StudentProfile'
import RoundOne from '../src/counselling/RoundOne'
import RoundTwo from '../src/counselling/RoundTwo'
import RoundThree from '../src/counselling/RoundThree'
import RoundFour from '../src/counselling/SpotRound'
import UploadMarks from './examination/UploadMarks';
import AllotmentDetails from './counselling/AllotmentDetails';
function App() {
  return (
    <div class="wrapper">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={
            <Dashboard />
          } />
          <Route path='/mba-dashboard' element={<MbaDashboard />} />
          <Route path='/other-dashboard' element={<OtherDashboard />} />
          <Route path='/PCM-dashboard' element={<PCMDashboard />} />
          <Route path='/PCB-dashboard' element={<PCBDashboard />} />
          <Route path='/Engg-dashboard' element={<EnggDashboard />} />
          <Route path='/change-markey' element={<Markey />} />
          <Route path='/counselling/change-markey' element={<CounsellingMarkey />} />
          <Route path='/registerd-student' element={<RegDashboard />} />
          <Route path='/login' element={<Login />} />
          <Route path='/logout' element={<Logout />} />
          <Route path='/referal/add-user' element={<AddUser />} />
          <Route path='/dashboard/bdm/user' element={<Register />} />
          <Route path='/referal/user-list' element={<RefferalList />} />
          <Route path='/referal/student' element={<RefferalStudent />} />
          {/* <Route path='/referal/link' element={<RefferalLink />} /> */}
          <Route path='/referal/link' element={<TotalRefferalLink />} />
          <Route path='/referal/login' element={<RefferalLogin />} />
          <Route path='/referal/view/:id' element={<ViewReportRefferal />} />
          <Route path='/total-partial-student/' element={<PartialStudents />} />
          <Route path='/total-unverified-student' element={<UnverifiedStudent />} />
          <Route path='/bdm/login' element={<BDMLogin />} />

          {/* // COUNSELLING ROUTES  */}
          <Route path='/counselling/dashboard' element={<CounsellingDashboard />} />
          <Route path='/counselling/dashboard/students' element={<CounsellingStudents />} />
          <Route path='/counselling/dashboard/allotment-info' element={<AllotmentDetails />} />
          <Route path='/counselling/dashboard/counselling-payments' element={<CounsellingPayments />} />
          <Route path='/counselling/dashboard/partial-payments' element={<PartialPayments />} />
          <Route path='/counselling/dashboard/seat-confirm' element={<ConfirmSeats />} />
          <Route path='/counselling/dashboard/seat-confirm/:id' element={<StudentProfile />} />
          <Route path='/counselling/dashboard/round-1' element={<RoundOne />} />
          <Route path='/counselling/dashboard/round-2' element={<RoundTwo />} />
          <Route path='/counselling/dashboard/round-3' element={<RoundThree />} />
          <Route path='/counselling/dashboard/spot-round' element={<RoundFour />} />
          {/* <Route path='/mba-dashboard' element={<MbaDashboard />} /> */}

          {/* examination routes  */}
          <Route path='/examinations/dashboard' element={<ExaminationsDashboard />} />
          <Route path='/examinations/dashboard/total' element={<TotalExaminer />} />
          <Route path='/examinations/dashboard/engg' element={<ExaminationsDashboard />} />
          <Route path='/examinations/dashboard/other' element={<ExaminationsDashboard />} />
          <Route path='/examinations/dashboard/mba' element={<ExaminationsDashboard />} />
          <Route path='/examinations/dashboard/pcm' element={<ExaminationsDashboard />} />
          <Route path='/examinations/dashboard/pcb' element={<ExaminationsDashboard />} />
          <Route path='/examinations/dashboard/upload-marks' element={<UploadMarks />} />
        </Routes>
      </BrowserRouter>
      {/* <Footer /> */}
    </div>
  );
}

export default App;
