import React, { Component, useState, useEffect } from 'react'
import Header from '../Header';
import Menu from '../Menu';
import 'jquery/dist/jquery.min.js';
// //Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
// import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import axios from 'axios';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import CollegeList from './CollegeList';

const customStyles = {
    content: {
        top: '55%',
        left: '50%',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: '5px solid #007bff',
        width: '60%',
        height: '90%',
        margin: 'auto',
        overflow: 'scroll',
    },
};
function RoundTwo() {
    const [data, setState] = useState([])

    console.log(data.length, "data..............................")
    const [college, setCollege] = useState([])
    const [roundOneCollege, setRoundOneCollege] = useState()
    console.log("college", college)

    // State array variable to save and show data
    const fetchData = () => {
        axios.get('/college-locked-list/two').then(res => {
            //Storing users detail in state array object
            setState(res.data.data);
            

            // console.log("dataAPI", res.data.userList);
        });
        $(document).ready(function () {
            setTimeout(function () {
                $('#example').DataTable(
                    {
                        pagingType: 'full_numbers',
                        pageLength: 15,
                        processing: true,
                        dom: 'Bfrtip',
                        buttons: ['copy', 'csv', 'print', 'excelHtml5', 'pdf'
                        ]
                    }
                );
            },
                1000
            );
        });
    }

    // modal action
    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    const [studentId,setStudentId] = useState('')
    const [requestType, setRequestType] = useState()
    // ON View button click
    const onClick = (college,_id,roundOneAllotedCollege,reqestType) => {
        // alert("is clicked")
        setCollege(college)
        setStudentId(_id)
        setRoundOneCollege(roundOneAllotedCollege);
        setRequestType(reqestType);
        setIsOpen(true)

    }


    useEffect(() => {
        fetchData()
    }, [])
    return (
        <div>
            <Header />
            <Menu />
            <div className="content-wrapper">

                <div className="container p-5">
                    <h1 style={{ textAlign: 'center', fontWeight: 'bold', textDecoration: 'underline' }}>Round Two</h1>
                    <h2>College Allotment</h2>
                    <table id="example" class="table table-hover table-bordered">
                        <thead>
                            <tr>
                                <th>Sl.No</th>
                                <th>Student Id</th>
                                <th>Student Name</th>
                                <th>State</th>
                                <th>Gender</th>
                                <th>Mobile No</th>
                                <th>Email-ID</th>

                                <th>Program</th>

                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data && data?.map((result, slNo) => {
                                return (

                                    <>

                                        <tr>
                                            <td>{slNo + 1}</td>
                                            <td>{result.user._id}</td>
                                            <td>{result.user.name}</td>
                                            <td>{result.personaldetails[0].state}</td>
                                            <td>{result.user.gender}</td>
                                            <td>{result.user.mobileNo}</td>
                                            <td>{result.user.email}</td>
                                            <td>{result.user.program}</td>
                                            <td><button onClick={() => { onClick(result.college,result._id,result.round_one_alloted_college,result.round_one_action) }} style={{ backgroundColor: '#007bff', color: 'white', borderRadius: '20%', border: '#007bff' }}>View</button></td>



                                        </tr>

                                    </>

                                )
                            })}



                        </tbody>
                    </table>

                </div>

            </div>

            <div>
                <button onClick={openModal}>Open Modal</button>
                <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}

                    contentLabel="Example Modal"
                >
                    <h2 ref={(_subtitle) => (subtitle = _subtitle)} style={{color:'#007bff',fontWeight:'bold'}}>List of Colleges</h2>
                    <h4 ref={(_subtitle) => (subtitle = _subtitle)} style={{color:'#007bff',fontWeight:'bold'}}> {requestType && requestType} Request</h4>
                    <CollegeList college={college} studentId={studentId} fetchData={fetchData} setIsOpen={setIsOpen} roundOneCollege={roundOneCollege} round="two" requestType={requestType}/>
                </Modal>
            </div>
        </div>
    )
}

export default RoundTwo
