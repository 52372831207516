import React, { Component, useState, useEffect } from 'react'
import Header from '../Header';
import Menu from '../Menu';
import 'jquery/dist/jquery.min.js';
// //Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
// import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import axios from 'axios';
function AllotmentDetails() {
    const [data, setState] = useState()
console.log(data)
    // State array variable to save and show data
    const fetchData = () => {
        axios.get('/counselling/student-allotment-details').then(res => {
            //Storing users detail in state array object
            setState(res.data.data);

            // console.log("dataAPI", res.data.userList);

        });
        $(document).ready(function () {
            setTimeout(function () {
                $('#example').DataTable(
                    {
                        pagingType: 'full_numbers',
                        pageLength: 15,
                        processing: true,
                        dom: 'Bfrtip',
                        buttons: ['copy', 'csv', 'print', 'excelHtml5', 'pdf'
                        ]
                    }
                );
            },
                2000
            );
        });
    }
    useEffect(() => {
        fetchData()
    }, [])
    return (
        <div>
            <Header />
            <Menu />
            <div className="content-wrapper">
                <div className="container p-5">
                    <table id="example" class="table table-hover table-bordered">
                        <thead>
                            <tr>
                                <th>Sl.No</th>
                               
                                <th>Student Name</th>
                                <th>Father's Name</th>
                                <th>Mobile No</th>
                                <th>Email-ID</th>
                                <th>State</th>
                              
                                <th>Program</th>
                                <th>Round One</th>
                                <th>Round Two</th>
                                <th>Round Three</th>
                     
                                {/* <th>Action</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.map((result, slNo) => {
            return (
                <>
                <tr>
                            <td>{slNo+1}</td>
                               
                                <td>{result.user.name}</td>
                                <td>{result.personal_details[0].fatherName} </td>
                                <td>{result.user.mobileNo}</td>
                                <td>{result.user.email}</td>
                                <td>{result.personal_details[0].state} </td>
                               
                                <td>{result.examination_marks[0].prog_opted}</td>
                                <td>
                                    {result.round_one_alloted_college ? JSON.stringify(result.round_one_alloted_college) : 'Not Alloted'}
                                </td>
                                <td>
                                    {result.round_two_alloted_college ? JSON.stringify(result.round_two_alloted_college) : 'Not Alloted'}
                                </td>
                                <td>
                                    {result.round_three_alloted_college ? JSON.stringify(result.round_three_alloted_college) : 'Not Alloted'}
                                </td>

                                {/* <td><button style={{backgroundColor:'#007bff',color:'white',borderRadius:'20%',border:'#007bff'}}>View</button></td> */}
                        </tr>
                </>

            )
        })}
                        </tbody>
                    </table>

                </div>

            </div>
        </div>
    )
}

export default AllotmentDetails
